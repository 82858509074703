<template>
    <div>
        <productos :categoria="categoria"></productos>
    </div>
</template>
<script>
import dataSource from '@/data.json';
import productos from '@/components/productos.vue';
export default {
    components: {productos},
    data() {
        return {
            categoria: dataSource.categorias[5],
        }
    },
}
</script>